.Layout {

  .ContentContainer {
    display: flex;
    flex-direction: row;
    min-height: calc(100vh - 53px);

    .Sidebar {
      position: fixed;
      top: 53px;
      width: 260px;
      height: calc(100% - 53px);
      box-sizing: border-box;
      background-color: #eeeeee;
      z-index: 998;
    }

    .Sidebar+.MainContent {
      margin-left: 260px;
      width: calc(100% - 260px);
    }

    .MainContent {
      position: relative;
      width: 100%;
      padding: 15px;
      margin-top: 53px;
      box-sizing: border-box;

      h1 {
        margin-top: 0px;
      }

      p:last-child {
        margin-bottom: 0px;
      }

    }

  }

}

.Unauthenticated {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #282c34;

  .UnauthenticatedContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border-radius: 3px;
    padding: 25px 40px;
    text-align: center;
    
    div {
      margin-bottom: 14px;
    }

    h1 {
      font-weight: normal;
      font-size: 26px;
      margin-top: 10px;
      margin-bottom: 12px;
    }

    p {
      margin-top: 0px;
      margin-bottom: 18px;
    }
  }
}

:global {
  a {
    color: black;

    &:hover {
      color: rgb(255, 215, 0);
    }
  }
}
