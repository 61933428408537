.Sidebar {

  position: relative;
  min-height: 100%;

  .SidebarNavigation {
    max-height: calc(100vh - 53px - 60px);
    overflow-x: hidden;
    overflow-y: auto;

    ul {
      list-style-type: none;
      padding: 0px;
      margin: 0px;
      margin-top: 4px;

      li {

        a {
          display: block;
          box-sizing: border-box;
          text-decoration: none;
          padding: 10px;
          color: #000000;

          &:hover {
            background-color: #dddddd;
          }

          :global .mdi-icon {
            width: 18px;
            height: 18px;
            margin-right: 5px;
            vertical-align: top;
          }

        }

        :global a.active {
          background-color: #dddddd;
        }

        button {
          width: 100%;
          border: 0px;
          margin: 0px;
          padding: 0px;
          text-decoration: none;
          color: #000000;
          outline: none;
          cursor: pointer;

          &:hover {
            background-color: #dddddd;
          }

          :global {
            .navigation-label {
              width: calc(100% - 20px);
              text-align: left;

              .mdi-icon {
                width: auto;
                height: 18px;
                margin-right: 5px;
                vertical-align: top;
              }

              span {
                width: calc(100% - 23px);
                padding-top: 2px;
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                vertical-align: top;
              }
            }

            .navigation-chevron {
              .mdi-icon {
                width: auto;
                height: 20px;
                vertical-align: top;
              }
            }
          }
        }

        ul {
          display: none;
          margin-top: 0px;

          li {
            a {
              padding-left: 25px;
              font-weight: normal;
            }

            ul {
              li {
                a {
                  padding-left: 40px;
                }
              }
            }
          }
        }

        &.SidebarOpen {
          &>ul {
            display: block;
          }
        }

      }
    }
  }

  .SidebarFooter {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 60px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: left bottom;
    box-sizing: border-box;
    padding-left: 10px;
  }

  .SidebarVersion {
    position: absolute;
    left: 0px;
    bottom: 0px;
    font-size: 9px;
    color: #4a4e4c;
    padding-left: 3px;
    padding-bottom: 2px;
  }

}