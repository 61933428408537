.Header {
    position: fixed;
    display: flex;
    width: 100%;
    background-color: #ffffff;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 1px solid #eeeeee;
    z-index: 999;

    .Logo {
        padding-left: 15px;
        font-size: 20px;
        line-height: 30px;
        font-weight: bold;

        a {
            text-decoration: none;
            color: #000000;

            img {
                height: 30px;
                vertical-align: top;
            }
        }
    }

    .Profile {
        padding: 10px 10px 10px 0px;
        display: flex;
        flex-direction: row;
        align-items: center;

        :global {
            .information {
                padding-left: 5px;

                .name {
                    font-weight: bold;
                    height: 15px;
                }

                .email {
                    font-size: 12px;
                    max-width: 200px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .logout {
                padding-left: 8px;
            }

        }

    }

    .Login {
        padding: 10px 10px 10px 0px;
    }

    .System {
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        padding: 8px 40px;
        border-radius: 5px;
        font-weight: bold;

        &.SystemDevelopment {
            background-color: red;
            color: #ffffff;
        }

        &.SystemNightly {
            background-color: red;
            color: #ffffff;
        }
    }

}